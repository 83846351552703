import { Col, Form, Overlay, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import withFooter from '../../../components/WithFooter';
import withNavBar from '../../../components/WithNavBar';
import "./index.scss"
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faFileZipper,
  faPen,
  faRotateLeft,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import COLORS from '../../../data/colors';
import useBreakpoint from '../../../hooks/useBreakpoint';
import CustomButton from '../../../components/CustomButton';
import CustomModal from '../../../components/CustomModal';
import { useState } from 'react';
import { useRef } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { StatusNomination } from '../../../data/statusNomination';
import GET_INCOMING_NOMINATION from '../../../graphql/queries/nomination/getIncomingNomination';
import ACCEPT_NOMINATION from '../../../graphql/mutations/nomination/acceptNomination';
import REJECT_DESTINATION from '../../../graphql/mutations/nomination/rejectDestination';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import toastConfiguration from "../../../data/toastConfiguration";
import { useEffect } from 'react';
import withTitle from '../../../components/withTitle';
import IconButton from '../../../components/IconButton';
import GET_IIA from '../../../graphql/queries/IIAs/getIIA';
import GET_FACTSHEET from '../../../graphql/queries/IIAs/getFactsheet';
import useUser from '../../../hooks/useUser';
import GET_EDIT_IIA from '../../../graphql/queries/IIAs/getEditIIA';
import UPDATE_IIA from '../../../graphql/mutations/IIAs/updateIIA';
import { FormControlUnstyled } from '@mui/base';
import TERMINATE_IIA from '../../../graphql/mutations/IIAs/terminateIIA';
import NOTIFY_PARTNER from '../../../graphql/mutations/IIAs/notifyPartner';

const SingleViewIIAs = () => {
    const [ size ] = useBreakpoint();
    const tooltipTargetPartnerButton = useRef(null);
    const tooltipTargetRevertButton = useRef(null);
    const { id } = useParams();
    const { setUser, user } = useUser();
    const navigate = useNavigate();

    const [showTooltipPartnerButton, setShowTooltipPartnerButton] = useState(false);
    const [showTooltipRevertButton, setShowTooltipRevertButton] = useState(false);
    const [showModalReject, setShowModalReject] = useState(false);
    const [showModalNominate, setShowModalNominate] = useState(false);

    const [calendarNomAutumn, setCalendarNomAutumn] = useState("");
    const [calendarNomSpring, setCalendarNomSpring] = useState("");
    const [calendarAppSpring, setCalendarAppSpring] = useState("");
    const [calendarAppAutumn, setCalendarAppAutumn] = useState("");
    const [applicationInfoEmail, setApplicationInfoEmail] = useState("");
    const [applicationInfoPhone, setApplicationInfoPhone] = useState("");
    const [applicationInfoWebsite, setApplicationInfoWebsite] = useState([]);
    const [additionalRequirements, setAdditionalRequirements] = useState([]);
    const [accessibilities, setAccessibilities] = useState([]);
    const [additionalInfos, setAdditionalInfos] = useState([]);
    const [housingInfo, setHousingInfo] = useState({});
    const [visaInfo, setVisaInfo] = useState({});
    const [insuranceInfo, setInsuranceInfo] = useState({});
    const [calendarNomAutumnPartner, setCalendarNomAutumnPartner] = useState("");
    const [calendarNomSpringPartner, setCalendarNomSpringPartner] = useState("");
    const [calendarAppSpringPartner, setCalendarAppSpringPartner] = useState("");
    const [calendarAppAutumnPartner, setCalendarAppAutumnPartner] = useState("");
    const [applicationInfoEmailPartner, setApplicationInfoEmailPartner] = useState("");
    const [applicationInfoPhonePartner, setApplicationInfoPhonePartner] = useState("");
    const [applicationInfoWebsitePartner, setApplicationInfoWebsitePartner] = useState([]);
    const [additionalRequirementsPartner, setAdditionalRequirementsPartner] = useState([]);
    const [additionalInfosPartner, setAdditionalInfosPartner] = useState([]);
    const [accessibilitiesPartner, setAccessibilitiesPartner] = useState([]);
    const [housingInfoPartner, setHousingInfoPartner] = useState({});
    const [visaInfoPartner, setVisaInfoPartner] = useState({});
    const [insuranceInfoPartner, setInsuranceInfoPartner] = useState({});
    const [signingContact, setSigningContact] = useState({});
    const [showModalAccept, setShowModalAccept] = useState(false);
    const [showModalTerminate, setShowModalTerminate] = useState(false);
    const [showModalRevert, setShowModalRevert] = useState(false);
    const [showModalModify, setShowModalModify] = useState(false);
    const [disableNotifyPartnerButton, setDisableNotifyPartnerButton] = useState(false);

    const { loading, error, data, refetch: refetchQuery } = useQuery(
        GET_IIA,
        {
          variables: {
            id: id
          }
        },
        
    );

    const [
        updateIIA, {
            loading: loadingUpdateIIA,
            error: errorUpdateIIA,
        },
      ] = useMutation(UPDATE_IIA);

      const [
        terminateIIA, { loading: loadingTerminateIIA }
      ] = useMutation(TERMINATE_IIA);

      const [
        notifyPartner, { loading: loadingNotifyPartner }
      ] = useMutation(NOTIFY_PARTNER);

    const {
        loading: loadingEditIIA,
        error: errorEditIIA,
        data: dataEditIIA, refetch: refetchQueryEdit
      } = useQuery(GET_EDIT_IIA, {variables: {id: id}, onCompleted: (data) =>  {
    
        console.log(data);
        
      }});

    const { loadingFactsheet, errorFactsheet, dataFactsheet, refetch: refetchFactsheet } = useQuery(
        GET_FACTSHEET,
        {
          variables: {
            heiID: user?.heiID
          }, onCompleted: (data) =>  { 
            
            setCalendarNomAutumn(data?.factsheet?.calendar?.student_nom_autumn_term);
            setCalendarNomSpring(data.factsheet?.calendar?.student_nom_spring_term);
            setCalendarAppAutumn(data?.factsheet?.calendar?.student_app_autumn_term);
            setCalendarAppSpring(data.factsheet?.calendar?.student_app_spring_term);
            setAdditionalRequirements(data.factsheet?.additional_requirements);
            setAccessibilities(data?.factsheet?.accessibilities);
            setAdditionalInfos(data?.factsheet?.additional_infos);
            setHousingInfo(data?.factsheet?.housing_info);
            setVisaInfo(data?.factsheet?.visa_info);
            setInsuranceInfo(data?.factsheet?.insurance_info);
            setApplicationInfoEmail(data?.factsheet?.application_info?.email);
            setApplicationInfoPhone(data.factsheet?.application_info?.phone_number?.e164);
            setApplicationInfoWebsite(data?.factsheet?.application_info?.website_urls);
            
        }
        }
    );

    const [ fetchFactsheetPartner, { loading: loadingFactsheetPartner, errorFactsheetPartner, dataFactsheetPartner, refetch: refetchFactsheetPartner, called: calledFactsheetPartner, }] =
    useLazyQuery(
        GET_FACTSHEET, { notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only', variables: {
            heiID: user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID
          },
            onCompleted: (data) =>  {
                setCalendarNomAutumnPartner(data?.factsheet?.calendar?.student_nom_autumn_term);
                setCalendarNomSpringPartner(data.factsheet?.calendar?.student_nom_spring_term);
                setCalendarAppAutumnPartner(data?.factsheet?.calendar?.student_app_autumn_term);
                setCalendarAppSpringPartner(data.factsheet?.calendar?.student_app_spring_term);
                setAdditionalRequirementsPartner(data.factsheet?.additional_requirements);
                setAccessibilitiesPartner(data?.factsheet?.accessibilities);
                setHousingInfoPartner(data?.factsheet?.housing_info);
                setVisaInfoPartner(data?.factsheet?.visa_info);
                setInsuranceInfoPartner(data?.factsheet?.insurance_info);
                setApplicationInfoEmailPartner(data?.factsheet?.application_info?.email);
                setApplicationInfoPhonePartner(data.factsheet?.application_info?.phone_number?.e164);
                setApplicationInfoWebsitePartner(data?.factsheet?.application_info?.website_urls);
                setAdditionalInfosPartner(data?.factsheet?.additional_infos);
            
            }}
    );

    useEffect(() => {
        // refetchQuery({id: id});
        refetchQueryEdit({id: id});
        async function fetchData(){
            await refetchQuery({
                id: id
            });
            if(data && !calledFactsheetPartner) {
                fetchFactsheetPartner({heiID: user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID});
            }
        }
        fetchData();
        
      }, []);

    useEffect(() => {
    
        function fetchData() {
            if(data?.iia?.partner[1]?.institution?.heiID) {
                fetchFactsheetPartner({heiID: user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID});
            }
        }
        data && fetchData();
    }, [data]);

    useEffect(() => {
    
        if (dataEditIIA) {
            let temp;
            temp = dataEditIIA?.iiaRaw?.partner[0]?.signing_contact;
            temp = temp ? {...temp} : {};
            temp.emails = [];
            temp.emails.push(user?.email);
            temp.contact_names = [];
            user?.userName && temp.contact_names.push({value: user?.userName});
            setSigningContact(temp);
        }
    }, [dataEditIIA]);


    const handleUpdateIIA = async () => {
        setShowModalAccept(false);
        const toastId = toast.loading("Saving Changes...", toastConfiguration);
        
        try {
      
            let result = await updateIIA({
                variables:  { iiaData: { id: id,
                    status: "approved", in_effect: false, type_of_communication: dataEditIIA?.iiaRaw?.type_of_communication,
                    partner: [{iia_id: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.iia_id : dataEditIIA?.iiaRaw?.partner[1]?.iia_id, 
                        hei_id: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.hei_id : dataEditIIA?.iiaRaw?.partner[1]?.hei_id, signing_date: new Date().toJSON().slice(0,-5)+"Z",
                        signing_contact: signingContact, ounit_name: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.ounit_name : dataEditIIA?.iiaRaw?.partner[1]?.ounit_name, 
                        contact: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.contact : dataEditIIA?.iiaRaw?.partner[1]?.contact,
                        ounit_id: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.ounit_id : dataEditIIA?.iiaRaw?.partner[1]?.ounit_id,
                        conditions_hash: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.conditions_hash : dataEditIIA?.iiaRaw?.partner[1]?.conditions_hash, 
                        iia_code: dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[0]?.iia_code : dataEditIIA?.iiaRaw?.partner[1]?.iia_code}, 
                        dataEditIIA?.iiaRaw?.partner[0]?.hei_id === user?.heiID ? dataEditIIA?.iiaRaw?.partner[1] : dataEditIIA?.iiaRaw?.partner[0]],
                    cooperation_conditions: dataEditIIA?.iiaRaw?.cooperation_conditions,
                   ...(data?.iia?.terminated) && {terminated: data?.iia?.terminated}
                }}
                  
                
            });
            if (!result?.errors) {
              toast.update(toastId, {
                ...toastConfiguration,
                render: "Successfully approved IIA",
                type: "success",
                isLoading: false,
              });
              refetchQuery({id: id});
              refetchQueryEdit({id: id});
            }
        } catch (error) {
          toast.update(toastId, {
            ...toastConfiguration,
            render: `Error on saving changes.`,
            type: "error",
            isLoading: false,
          });
          alert("There was an error with approving IIA: " + error);
        }
      };

      const handleRevertIIA = async () => {
        setShowModalRevert(false);
        const toastId = toast.loading("Saving Changes...", toastConfiguration);
                
        const rawApprovedCopy = dataEditIIA?.iiaRaw?.approved_copy;
        let tempArroved = JSON.parse(decodeURIComponent(rawApprovedCopy.replace(/\+/g, '%20')), function(k, v) { 
            return (typeof v === "object" || isNaN(v)) ? v : (k === "mobilities_per_year" || k === "total_days_per_year" || k === "total_months_per_year" || k === "person_gender") ? parseInt(v, 10) : v; 
        });
        
        try {
      
            let result = await updateIIA({
                variables:  { iiaData: { id: id,
                    status: "submitted", in_effect: true, type_of_communication: tempArroved?.type_of_communication,
                    partner: [{iia_id: tempArroved?.partner[0]?.iia_id?.toString(), 
                        hei_id: tempArroved?.partner[0]?.hei_id, signing_date: tempArroved?.partner[0]?.signing_date,
                        signing_contact: tempArroved?.partner[0]?.signing_contact, ounit_name: tempArroved?.partner[0]?.ounit_name, 
                        contact: tempArroved?.partner[0]?.contact,
                        ounit_id: tempArroved?.partner[0]?.ounit_id,
                        conditions_hash: tempArroved?.partner[0]?.conditions_hash, 
                        iia_code: tempArroved?.partner[0]?.iia_code?.toString()},
                        {iia_id: tempArroved?.partner[1]?.iia_id?.toString(), 
                        hei_id: tempArroved?.partner[1]?.hei_id, signing_date: tempArroved?.partner[1]?.signing_date,
                        signing_contact: tempArroved?.partner[1]?.signing_contact, ounit_name: tempArroved?.partner[1]?.ounit_name, 
                        contact: tempArroved?.partner[1]?.contact,
                        ounit_id: tempArroved?.partner[1]?.ounit_id,
                        conditions_hash: tempArroved?.partner[1]?.conditions_hash, 
                        iia_code: tempArroved?.partner[1]?.iia_code?.toString()} 
                        ],
                    cooperation_conditions: tempArroved?.cooperation_conditions,
                    terminated: tempArroved?.terminated === "1" ? true : false
                }}
                  
                
            });
            if (!result?.errors) {
              toast.update(toastId, {
                ...toastConfiguration,
                render: "Successfully reverted IIA",
                type: "success",
                isLoading: false,
              });
              refetchQuery({id: id});
              refetchQueryEdit({id: id});
            }
        } catch (error) {
          toast.update(toastId, {
            ...toastConfiguration,
            render: `Error on saving changes.`,
            type: "error",
            isLoading: false,
          });
          alert("There was an error with reverting IIA: " + error);
        }
      };

      const handleTerminate = async () => {
        const toastId = toast.loading("Terminating IIA...", toastConfiguration);
      
        try {
            const result = await terminateIIA({
                variables: {
                  id: id,
                },
              });
              setShowModalTerminate(false);
                if (!result?.errors) {
                    refetchQuery({id: id});
                    refetchQueryEdit({id: id});
                    toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully terminated IIA`,
                    type: "success",
                    isLoading: false,
                    });
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Termination of IIA failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
    };

    const handleNotifyPartner = async () => {
        const toastId = toast.loading("Notifying partner...", toastConfiguration);
      
        try {
            const result = await notifyPartner({
                variables: {
                  id: id,
                },
              });
                if (!result?.errors) {
                    refetchQuery({id: id});
                    refetchQueryEdit({id: id});
                    toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Succesfully notified Partner`,
                    type: "success",
                    isLoading: false,
                    });
                    setDisableNotifyPartnerButton(true);
                    setTimeout(() => {
                        setDisableNotifyPartnerButton(false);
                    }, 60000);
            }
        } catch (error) {
            if (error) {
                toast.update(toastId, {
                    ...toastConfiguration,
                    render: `Notification of Partner failed`,
                    type: "error",
                    isLoading: false,
                  });
            }
        }
    };

    if (loading || loadingFactsheet) return (
        <div
            style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            <Spinner
                as="div"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                className="align-middle my-auto"
            />
            <span>Loading...</span>
        </div>
    )
    if (error)
      return (
        <div className="text-center text-danger mx-auto">An error occured</div>
      );

    const currentUserIiaId = data?.iia?.partner?.find(p => p?.institution?.heiID === user?.heiID);
    const partner = data?.iia?.partner?.find(p => p?.institution?.heiID !== user?.heiID);

  return (
    <>
    {(size !== "xs" && size !== "sm" && size !== "md") && ( <>
    <Row style={ size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 75}}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="IIA INFORMATION" style={{background: "#ffff"}}>
            <Table responsive>
                <thead className='thead-font'>
                    <tr>
                    <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 24}} className='border-hidden label-style'>YOUR ID</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>PARTNER ID</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>PARTNER</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>LAST EDITED</th>
                    <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STATUS</th>
                    <th className='border-hidden'><span className={data?.iia?.type_of_communication === "dashboard" ? "dashboard-dash-icon" : 'dashboard-ewp-icon'}></span></th>
                    </tr>
                </thead>
                <tbody className='tbody-font'>
                    <tr>
                    <td style={{paddingTop: 0, paddingBottom: 20, paddingLeft: 24}} className='border-hidden body'>{data?.iia?.type_of_communication==="dashboard"?(currentUserIiaId?.iia_id?currentUserIiaId?.iia_id:partner?.iia_id):currentUserIiaId?.iia_id}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.iia?.type_of_communication==="dashboard"?(partner?.iia_id?partner?.iia_id:currentUserIiaId?.iia_id):partner?.iia_id}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID} <br/><span style={{fontStyle: "italic"}}>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.name : data?.iia?.partner[0]?.institution?.name}</span></td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{data?.iia?.changed_time}</td>
                    <td style={{paddingTop: 0, paddingBottom: 20}} className='border-hidden  body'>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.terminated ? "Terminated by You" : "Submitted by You" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? data?.iia?.terminated ? "Terminated by Partner" : "Submitted by Partner" :
                    data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.terminated ? "Termination Approved by You" : "Approved by You" : data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? data?.iia?.terminated ? "Termination Approved by Partner" : "Approved by Partner" : data?.iia?.status?.toLowerCase() === "draft" ? data?.iia?.terminated ? "Terminated" : "Draft" : data?.iia?.terminated ? "Termination Approved by All" : "Approved by All" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? data?.iia?.terminated ? "Terminated by You" : "Submitted by You" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.terminated ? "Terminated by Partner" : "Submitted by Partner" :
                    data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? data?.iia?.terminated ? "Termination Approved by You" : "Approved by You" : data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.terminated ? "Termination Approved by Partner" : "Approved by Partner" : data?.iia?.status?.toLowerCase() === "draft" ? data?.iia?.terminated ? "Terminated" : "Draft" : data?.iia?.terminated ? "Termination Approved by All" : "Approved by All"}</td>
                    </tr>
                </tbody>
                </Table>
                <Row><Col lg={11}>{(!data?.iia?.valid_for_approval && data?.iia?.status === "approved-by-all") ? <div style={{paddingLeft: 24, paddingBottom: 10}}><span style={{color:"orange"}}>!! </span>This IIA contains invalid ISCED F-Codes or is missing Language Requirements. The approvals are still valid. If you wish to terminate this IIA, you need to modify and mutually approve it.

                </div> : (!data?.iia?.valid_for_approval && data?.iia?.status !== "approved-by-all") && <div style={{paddingLeft: 24, paddingBottom: 10}}><span style={{color:"orange"}}>!! </span>This IIA contains invalid ISCED F-Codes or is missing Language Requirements. Please edit/modify the IIA.

                </div>}
                </Col>
                    <Col lg={11}>{data?.iia?.type_of_communication === "ewp" && <div style={{paddingLeft: 24, paddingBottom: 10}}>*Please note that the Partner HEI data is managed by a third-party provider. That means that this particular IIA is represented by a different ID in their system.

                </div>}
                </Col>
                <Col lg={1}>
                    <a href={`/iias/export/${id}`} target="_blank">
                        <span className='pdf-icon-button'>
                        <IconButton
                            variant={"download"}
                            title={"Export Pdf"}
                        />
                        </span>
                    </a>
                    </Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" && size !== "xl" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36}}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col lg={6} style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="YOUR INSTITUTION DATA" style={{background: "#ffff"}}>
                        <Row style={{ paddingLeft: 10,paddingBottom: 15 }}>
                            <Col>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.name : data?.iia?.partner[1]?.institution?.name}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.country : data?.iia?.partner[1]?.institution?.country}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.heiID : data?.iia?.partner[1]?.institution?.heiID}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.erasmusCode : data?.iia?.partner[1]?.institution?.erasmusCode}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.ounit_name : data?.iia?.partner[1]?.ounit_name}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? `${data?.iia?.partner[0]?.signing_contact?.contact_names ? data?.iia?.partner[0]?.signing_contact?.contact_names : ""}, 
                                ${data?.iia?.partner[0]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[0]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[0]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[0]?.signing_contact?.phone_numbers[0]?.e164 : ""}`
                                : `${data?.iia?.partner[1]?.signing_contact?.contact_names ? data?.iia?.partner[1]?.signing_contact?.contact_names : ""}, 
                                ${data?.iia?.partner[1]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[1]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[1]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[1]?.signing_contact?.phone_numbers[0]?.e164 : ""}`}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.contact?.length > 0 && data?.iia?.partner[0]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                ) : data?.iia?.partner[1]?.contact?.length > 0 && data?.iia?.partner[1]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Sign Date:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ?
                                 data?.iia?.partner[0]?.signing_date && new Date(data?.iia?.partner[0]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[0]?.signing_date?.substring(0, data?.iia?.partner[0]?.signing_date?.length - 6) 
                                 : data?.iia?.partner[0]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"}) :
                                  data?.iia?.partner[1]?.signing_date && new Date(data?.iia?.partner[1]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[1]?.signing_date?.substring(0, data?.iia?.partner[1]?.signing_date?.length - 6) 
                                  : data?.iia?.partner[1]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"})}</Col></Row>
                            </Col>
                        </Row>
                        </Tab>
                    </Tabs>
                </Col>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="PARTNER INSTITUTION DATA" style={{background: "#ffff"}}>
                            <Row style={{ paddingLeft: 10,paddingBottom: 15 }}>
                                <Col>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.name : data?.iia?.partner[0]?.institution?.name}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.country : data?.iia?.partner[0]?.institution?.country}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.erasmusCode : data?.iia?.partner[0]?.institution?.erasmusCode}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.ounit_name : data?.iia?.partner[0]?.ounit_name}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? `${data?.iia?.partner[1]?.signing_contact?.contact_names ? data?.iia?.partner[1]?.signing_contact?.contact_names : ""}, 
                                ${data?.iia?.partner[1]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[1]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[1]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[1]?.signing_contact?.phone_numbers[0]?.e164 : ""}`
                                : `${data?.iia?.partner[0]?.signing_contact?.contact_names ? data?.iia?.partner[0]?.signing_contact?.contact_names : ""}, 
                                ${data?.iia?.partner[0]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[0]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[0]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[0]?.signing_contact?.phone_numbers[0]?.e164 : ""}`}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                ) : data?.iia?.partner[0]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Sign Date:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.signing_date && 
                                    new Date(data?.iia?.partner[1]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[1]?.signing_date?.substring(0, data?.iia?.partner[1]?.signing_date?.length - 6) 
                                    : data?.iia?.partner[1]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"}) : 
                                    data?.iia?.partner[0]?.signing_date && new Date(data?.iia?.partner[0]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[0]?.signing_date?.substring(0, data?.iia?.partner[0]?.signing_date?.length - 6) 
                                    : data?.iia?.partner[0]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"})}</Col></Row>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Your Data" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmail}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhone}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsite?.length > 0 && applicationInfoWebsite}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <Table border={1} frame="void" rules="rows">
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>REQUIREMENT</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DETAILS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ECTS NUMBER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBJECT FIELD (ISCED)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL (EQF)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>WEBSITE</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {additionalRequirements?.map((elem, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.details}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.ects}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.isced_f_code + elem?.isced_f_code_name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.eqf_level}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'><a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilities?.map((elem, index) => (
                                            elem?.type?.startsWith("infrastructure") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilities?.map((elem, index) => (
                                            elem?.type?.startsWith("service") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfo?.email}{housingInfo?.phone_number?.e164 ? ", " + housingInfo?.phone_number?.e164 : ""}{housingInfo?.website_urls ? ", " + housingInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfo?.email}{visaInfo?.phone_number?.e164 ? ", " + visaInfo?.phone_number?.e164 : ""}{visaInfo?.website_urls ? ", " + visaInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfo?.email}{insuranceInfo?.phone_number?.e164 ? ", " + insuranceInfo?.phone_number?.e164 : ""}{insuranceInfo?.website_urls ? ", " + insuranceInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfos?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Row><Col style={{background: "#FFFFFF", borderRadius: 10, marginLeft: 8, marginRight: 8}}><div style={{marginLeft: 30, marginTop: 20, marginBottom: 20}} className='body'>A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.<br/>
[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]<br/>
*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</div></Col></Row>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="partner" id="partner" title="Partner Data" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmailPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhonePartner}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsitePartner?.length > 0 && applicationInfoWebsitePartner}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <Table border={1} frame="void" rules="rows">
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>REQUIREMENT</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DETAILS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ECTS NUMBER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>SUBJECT FIELD (ISCED)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>STUDY LEVEL (EQF)</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>WEBSITE</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {additionalRequirementsPartner?.map((elem, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.details}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.ects}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.isced_f_code + elem?.isced_f_code_name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.eqf_level}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'><a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilitiesPartner?.map((elem, index) => (
                                            elem?.type?.startsWith("infrastructure") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>NAME</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>DESCRIPTION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CONTACT</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {accessibilitiesPartner?.map((elem, index) => (
                                            elem?.type?.startsWith("service") && <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.name}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.description}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfoPartner?.email}{housingInfoPartner?.phone_number?.e164 ? ", " + housingInfoPartner?.phone_number?.e164 : ""}{housingInfoPartner?.website_urls ? ", " + housingInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfoPartner?.email}{visaInfoPartner?.phone_number?.e164 ? ", " + visaInfoPartner?.phone_number?.e164 : ""}{visaInfoPartner?.website_urls ? ", " + visaInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfoPartner?.email}{insuranceInfoPartner?.phone_number?.e164 ? ", " + insuranceInfoPartner?.phone_number?.e164 : ""}{insuranceInfoPartner?.website_urls ? ", " + insuranceInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfosPartner?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Row><Col style={{background: "#FFFFFF", borderRadius: 10, marginLeft: 8, marginRight: 8}}><div style={{marginLeft: 30, marginTop: 20, marginBottom: 20}} className='body'>A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.<br/>
[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]<br/>
*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</div></Col></Row>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="conditions" id="conditions" title="Conditions" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                
                    {data?.iia?.cooperation_conditions?.staff_teachers.length > 0 && data?.iia?.cooperation_conditions?.staff_teachers.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Staff Teacher:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Teachers</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.staff_trainings.length > 0 && data?.iia?.cooperation_conditions?.staff_trainings.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Staff Training:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Trainings</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.student_studies.length > 0 && data?.iia?.cooperation_conditions?.student_studies.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Student Study:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student studies"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.student_traineeships.length > 0 && data?.iia?.cooperation_conditions?.student_traineeships.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><span className='blue-text'>Student Traineeship:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")} </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student trainships"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                </Col>
            </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={size !== "md" && size !== "lg" ? {marginLeft: 35, marginRight: 35} : { marginLeft: 0, marginRight: 0}}>
            
        <Col style={{marginTop: 36, display: "flex", justifyContent: "flex-start"}} md={3} lg={3} xl={3}>
            {disableNotifyPartnerButton ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{"You will be able to click the button and notify the partner again soon."}</Tooltip>}>
                <span className="d-inline-block">
                {data?.iia?.type_of_communication === "ewp" && <CustomButton
                  buttonType={"primary"}
                  disabled
                  small={true}
                  text={"Notify partner"}
                  handleClick={handleNotifyPartner}
            />}
                </span>
            </OverlayTrigger> : <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{"Notify partner about your current state of the IIA."}</Tooltip>}>
                <span className="d-inline-block">
                {data?.iia?.type_of_communication === "ewp" && <CustomButton
                  buttonType={"primary"}
                  disabled={disableNotifyPartnerButton}
                  small={true}
                  text={"Notify partner"}
                  handleClick={handleNotifyPartner}
            />}
                </span>
            </OverlayTrigger>}
        </Col>
        <Col style={{marginTop: 36, display: "flex", justifyContent: "flex-end"}}>

            {loadingUpdateIIA || !(data?.iia?.status === "approved-by-all") || (!data?.iia?.valid_for_approval && data?.iia?.status === "approved-by-all") ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{!data?.iia?.valid_for_approval && data?.iia?.status === "approved-by-all" ? "This IIA cannot be terminated because it contains invalid ISCED F-Codes or is missing Language Requirements. Please modify and mutually approve the IIA in order to terminate it." : "You can terminate an IIA only if it’s Approved by All."}</Tooltip>}>
                <span className="d-inline-block">
                {<CustomButton buttonType={"decline"} text={"terminate iia"} styles={{marginLeft: 20}} icon={faXmark} hasIcon={true} iconColor={COLORS.white} 
                disabled handleClick={() => {setShowModalTerminate(true);}}/>}
                </span>
            </OverlayTrigger> : data?.iia?.status === "approved-by-all"  && !data?.iia?.terminated && <CustomButton buttonType={"decline"} text={"terminate iia"} styles={{marginLeft: 20}} icon={faXmark} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || !(data?.iia?.status === "approved-by-all")} handleClick={() => {setShowModalTerminate(true);}}/>}
        
            {/* { process.env.NODE_ENV === "development" && (user?.heiID === "uni-foundation.eu" || user?.heiID === "brussels.uni-foundation.eu") && data?.iia?.status === "approved-by-all"  && !data?.iia?.terminated && <CustomButton buttonType={"decline"} text={"terminate iia"} styles={{marginLeft: 20}} icon={faXmark} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || !(data?.iia?.status === "approved-by-all")} handleClick={() => {setShowModalTerminate(true);}}/>} */}

            {loadingUpdateIIA || !(dataEditIIA?.iiaRaw?.approved_copy) || (data?.iia?.status === "approved-by-all") ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{!dataEditIIA?.iiaRaw?.approved_copy ? "You cannot revert the IIA because there is no approval yet" : "You cannot revert the IIA because it’s already approved by all"}</Tooltip>}>
                <span className="d-inline-block">
                    <CustomButton buttonType={"primary"} text={"revert iia"} styles={{marginLeft: 20}} icon={faRotateLeft} hasIcon={true} iconColor={COLORS.white} 
                    disabled/>
                </span>
            </OverlayTrigger> : <CustomButton buttonType={"primary"} text={"revert iia"} styles={{marginLeft: 20}} icon={faRotateLeft} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || !(dataEditIIA?.iiaRaw?.approved_copy) || (data?.iia?.status === "approved-by-all")} handleClick={() => setShowModalRevert(true)}/>}

            {loadingUpdateIIA || (data?.iia?.terminated && data?.iia?.status !== "approved-by-all") ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{"You cannot edit the IIA during termination process."}</Tooltip>}>
                <span className="d-inline-block">
                <CustomButton buttonType={"orange"} text={data?.iia?.status === "approved-by-all" ? "modify iia" : "edit iia"} styles={{marginLeft: 20}} icon={faPen} hasIcon={true} iconColor={COLORS.white} 
            disabled handleClick={() => navigate("/iias/edit/" + id)}/>
                </span>
            </OverlayTrigger> : <CustomButton buttonType={"orange"} text={(data?.iia?.status === "approved-by-all") ? "modify iia" : "edit iia"} styles={{marginLeft: 20}} icon={faPen} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || (data?.iia?.status === "approved-by-all" && user?.heiID !== "uni-foundation.eu" && user?.heiID !== "brussels.uni-foundation.eu" && data?.iia?.terminated) || (data?.iia?.terminated && data?.iia?.status !== "approved-by-all")} handleClick={() => data?.iia?.status === "approved-by-all" ? setShowModalModify(true) : navigate("/iias/edit/" + id)}/>}
            
            {loadingUpdateIIA || data?.iia?.status === "approved-by-all" || data?.iia?.status === "draft" || (data?.iia?.type_of_communication === "ewp" && !(data?.iia?.partner[0]?.iia_id && data?.iia?.partner[1]?.iia_id)) || !data?.iia?.valid_for_approval ? 
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="position-absolute">{data?.iia?.terminated ? "IIA’s termination is already Approved by All." : !data?.iia?.valid_for_approval ? "This IIA cannot be approved because it contains invalid ISCED F-Codes or is missing Language Requirements. Please edit/modify the IIA." : "IIA is already Approved by All or Partner has not shared their local IIA ID."}</Tooltip>}>
                <span className="d-inline-block">
                <CustomButton buttonType={"accept"} text={data?.iia?.terminated ? "approve termination" : "approve iia"} styles={{marginLeft: 20}} icon={faCheck} hasIcon={true} iconColor={COLORS.white} 
            disabled handleClick={() => setShowModalAccept(true)}/>
                </span>
            </OverlayTrigger> : <CustomButton buttonType={"accept"} text={data?.iia?.terminated ? "approve termination" : "approve iia"} styles={{marginLeft: 20}} icon={faCheck} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || data?.iia?.status === "approved-by-all" || data?.iia?.status === "draft" || (data?.iia?.type_of_communication === "ewp" && !(data?.iia?.partner[0]?.iia_id && data?.iia?.partner[1]?.iia_id))} handleClick={() => setShowModalAccept(true)}/>}

            {/* <CustomButton buttonType={"orange"} text={(process.env.NODE_ENV === "development" && (user?.heiID === "uni-foundation.eu" || user?.heiID === "brussels.uni-foundation.eu") && data?.iia?.status === "approved-by-all") ? "modify iia" : "edit iia"} styles={{marginLeft: 20}} icon={faPen} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || (data?.iia?.status === "approved-by-all" && user?.heiID !== "uni-foundation.eu" && user?.heiID !== "brussels.uni-foundation.eu" && data?.iia?.terminated) || (data?.iia?.terminated && data?.iia?.status !== "approved-by-all")} handleClick={() => navigate("/iias/edit/" + id)}/> */}
            {/* <CustomButton buttonType={"accept"} text={data?.iia?.terminated ? "approve termination" : "approve iia"} styles={{marginLeft: 20}} icon={faCheck} hasIcon={true} iconColor={COLORS.white} 
            disabled={loadingUpdateIIA || data?.iia?.status === "approved-by-all" || data?.iia?.status === "draft" || (data?.iia?.type_of_communication === "ewp" && !(data?.iia?.partner[0]?.iia_id && data?.iia?.partner[1]?.iia_id))} handleClick={() => setShowModalAccept(true)}/> */}
        </Col>
    </Row>
</>
  )}
  {(size === "xs" || size === "sm" || size === "md") && ( <>
    <Row style={{marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 75}}>
            <Tabs
            id="application-info"
            >
            <Tab eventKey="home" title="IIA INFORMATION" style={{background: "#ffff"}}>
            <Row style={{marginLeft: 25,marginRight: 25}}>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='thead-font label-style'>YOUR ID:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 17}} className='tbody-font body'>{data?.iia?.type_of_communication==="dashboard"?(currentUserIiaId?.iia_id?currentUserIiaId?.iia_id:partner?.iia_id):currentUserIiaId?.iia_id}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>PARTNER ID:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{data?.iia?.type_of_communication==="dashboard"?(partner?.iia_id?partner?.iia_id:currentUserIiaId?.iia_id):partner?.iia_id}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>PARTNER:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID} <br/><span style={{fontStyle: "italic"}}>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.name : data?.iia?.partner[0]?.institution?.name}</span></Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='thead-font label-style'>LAST EDITED:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6}} className='tbody-font body'>{data?.iia?.changed_time}</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='thead-font label-style'>STATUS:</Col>
                    <Col xs={6} sm={6} style={{marginTop: 6,marginBottom: 25}} className='tbody-font body'>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? "Submitted by You" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? "Submitted by Partner" :
                    data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? "Approved by You" : data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? "Approved by Partner" : data?.iia?.status?.toLowerCase() === "draft" ? "Draft" : "Approved by All" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? "Submitted by You" : data?.iia?.status?.toLowerCase() === "submitted" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? "Submitted by Partner" :
                    data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[1]?.institution?.heiID ? "Approved by You" : data?.iia?.status?.toLowerCase() === "approved" 
                    && data?.iia?.last_author === data?.iia?.partner[0]?.institution?.heiID ? "Approved by Partner" : data?.iia?.status?.toLowerCase() === "draft" ? "Draft" : "Approved by All"}</Col>
                    <Col xs={12} sm={12} style={{marginTop: 6,marginBottom: 15}} className='tbody-font body text-end'>
                    {/* <Overlay
                            target={tooltipTarget.current}
                            show={showTooltip}
                            placement="top"
                        >
                            {(props) => (
                            <Tooltip
                                id="overlay-example"
                                {...props}
                                className="position-absolute"
                            >
                                    Download All Files
                            </Tooltip>
                            )}
                        </Overlay> */}
                        {/* <div ref={tooltipTarget}> */}
                            {/* <ExportZip id={field_id} onMouseEnter={handleShowTooltip} onMouseLeave={handleCloseTooltip} studentName={field_student_name}/> */}
                        {/* </div> */}
                        </Col>
                </Row>
                <Row><Col xs={12}>{data?.iia?.type_of_communication === "ewp" && <div style={{paddingLeft: 24, paddingBottom: 10}}>*Please note that the Partner HEI data is managed by a third-party provider. That means that this particular IIA is represented by a different ID in their system.

                </div>}
                </Col>
                <Col xs={12}><span className={data?.iia?.type_of_communication === "dash" ? "dashboard-dash-icon" : 'dashboard-ewp-icon'} style={{marginTop: -5, float: "left"}}></span>
                <a href={`/iias/export/${id}`} target="_blank">
                        <span style={{marginRight: 0, marginTop: 0}} className='pdf-icon-button'>
                        <IconButton
                            variant={"download"}
                            title={"Export Pdf"}
                        />
                        </span>
                    </a></Col>
                </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={{ marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36}}>
            <Tabs
            id="second-tab"
            >
            <Tab eventKey="general-info" id="general-info" title="General Info" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col lg={6} style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="YOUR INSTITUTION DATA" style={{background: "#ffff"}}>
                        <Row style={{ paddingLeft: 10,paddingBottom: 15 }}>
                            <Col>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.name : data?.iia?.partner[1]?.institution?.name}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.country : data?.iia?.partner[1]?.institution?.country}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.heiID : data?.iia?.partner[1]?.institution?.heiID}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.institution?.erasmusCode : data?.iia?.partner[1]?.institution?.erasmusCode}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.ounit_name : data?.iia?.partner[1]?.ounit_name}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? `${data?.iia?.partner[0]?.signing_contact?.contact_names}, 
                                ${data?.iia?.partner[0]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[0]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[0]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[0]?.signing_contact?.phone_numbers[0]?.e164 : ""}`
                                : `${data?.iia?.partner[1]?.signing_contact?.contact_names}, 
                                ${data?.iia?.partner[1]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[1]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[1]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[1]?.signing_contact?.phone_numbers[0]?.e164 : ""}`}</Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[0]?.contact?.length > 0 && data?.iia?.partner[0]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                ) : data?.iia?.partner[1]?.contact?.length > 0 && data?.iia?.partner[1]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                                <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Sign Date:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ?
                                 data?.iia?.partner[0]?.signing_date && new Date(data?.iia?.partner[0]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[0]?.signing_date?.substring(0, data?.iia?.partner[0]?.signing_date?.length - 6) 
                                 : data?.iia?.partner[0]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"}) :
                                  data?.iia?.partner[1]?.signing_date && new Date(data?.iia?.partner[1]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[1]?.signing_date?.substring(0, data?.iia?.partner[1]?.signing_date?.length - 6) 
                                  : data?.iia?.partner[1]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"})}</Col></Row>
                            </Col>
                        </Row>
                        </Tab>
                    </Tabs>
                </Col>
                <Col style={{marginTop: 20, marginBottom: 20}}>
                    <Tabs
                        defaultActiveKey="home"
                        id="student-info"
                        >
                        <Tab eventKey="home" title="PARTNER INSTITUTION DATA" style={{background: "#ffff"}}>
                            <Row style={{ paddingLeft: 10,paddingBottom: 15 }}>
                                <Col>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Name:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.name : data?.iia?.partner[0]?.institution?.name}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Country:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.country : data?.iia?.partner[0]?.institution?.country}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>SCHAC Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.heiID : data?.iia?.partner[0]?.institution?.heiID}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Erasmus Code:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.institution?.erasmusCode : data?.iia?.partner[0]?.institution?.erasmusCode}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Org. Unit:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.ounit_name : data?.iia?.partner[0]?.ounit_name}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Approving Person:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? `${data?.iia?.partner[1]?.signing_contact?.contact_names}, 
                                ${data?.iia?.partner[1]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[1]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[1]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[1]?.signing_contact?.phone_numbers[0]?.e164 : ""}`
                                : `${data?.iia?.partner[0]?.signing_contact?.contact_names}, 
                                ${data?.iia?.partner[0]?.signing_contact?.emails.length > 0 ? data?.iia?.partner[0]?.signing_contact?.emails[0] : ""}, ${data?.iia?.partner[0]?.signing_contact?.phone_numbers.length > 0 ? data?.iia?.partner[0]?.signing_contact?.phone_numbers[0]?.e164 : ""}`}</Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Contact Persons:</Col><Col><ul>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                ) : data?.iia?.partner[0]?.contact?.map(
                                    (elem, index) => (
                                        <li>{elem?.contact_names}{", "}{elem?.emails[0]}{", "}{elem?.phone_numbers[0]?.e164}</li>
                                    )
                                )}</ul></Col></Row>
                                    <Row style={{ paddingTop: 10, paddingBottom: 2 }}><Col className='border-hidden label-style' xs={4} sm={3} md={3}>Sign Date:</Col><Col>{user?.heiID === data?.iia?.partner[0]?.institution?.heiID ? data?.iia?.partner[1]?.signing_date && 
                                    new Date(data?.iia?.partner[1]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[1]?.signing_date?.substring(0, data?.iia?.partner[1]?.signing_date?.length - 6) 
                                    : data?.iia?.partner[1]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"}) : 
                                    data?.iia?.partner[0]?.signing_date && new Date(data?.iia?.partner[0]?.signing_date?.endsWith("+02:00") ? data?.iia?.partner[0]?.signing_date?.substring(0, data?.iia?.partner[0]?.signing_date?.length - 6) 
                                    : data?.iia?.partner[0]?.signing_date).toLocaleDateString('en-GB',{year: "numeric",month: "2-digit",day: "2-digit"})}</Col></Row>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="documents" id="documents" title="Your Data" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumn}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpring}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmail}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhone}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsite?.length > 0 && applicationInfoWebsite}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                            {additionalRequirements?.map((elem, index) => (
                                <div class="p-3  justify-content-around align-items-center">
                            
                                <div className="row">
                                    <div className="col table-header">
                                        {"REQUIREMENT:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"DETAILS:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.details}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"ECTS NUMBER:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.ects}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"SUBJECT FIELD (ISCED):"}
                                    </div>
                                    <div className="col body">
                                        {elem?.isced_f_code + elem?.isced_f_code_name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"STUDY LEVEL (EQF):"}
                                    </div>
                                    <div className="col body">
                                        {elem?.eqf_level}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"WEBSITE:"}
                                    </div>
                                    <div className="col body">
                                    <a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a>
                                    </div>
                                </div>
                                </div>
                            ))}
                            <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                {accessibilities?.map((elem, index) => (
                                    elem?.type === "infrastructure" && <div class="p-3  justify-content-around align-items-center">
                                    <div className="row">
                                        <div className="col table-header">
                                            {"NAME:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"DESCRIPTION:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.description}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"CONTACT:"}
                                        </div>
                                        <div className="col body">
                                        {elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                
                                
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                {accessibilities?.map((elem, index) => (
                                    elem?.type === "service" && <div class="p-3  justify-content-around align-items-center">
                                    <div className="row">
                                        <div className="col table-header">
                                            {"NAME:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"DESCRIPTION:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.description}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"CONTACT:"}
                                        </div>
                                        <div className="col body">
                                        {elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfo?.email}{housingInfo?.phone_number?.e164 ? ", " + housingInfo?.phone_number?.e164 : ""}{housingInfo?.website_urls ? ", " + housingInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfo?.email}{visaInfo?.phone_number?.e164 ? ", " + visaInfo?.phone_number?.e164 : ""}{visaInfo?.website_urls ? ", " + visaInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfo?.email}{insuranceInfo?.phone_number?.e164 ? ", " + insuranceInfo?.phone_number?.e164 : ""}{insuranceInfo?.website_urls ? ", " + insuranceInfo?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfos?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Row><Col style={{background: "#FFFFFF", borderRadius: 10, marginLeft: 8, marginRight: 8}}><div style={{marginLeft: 30, marginTop: 20, marginBottom: 20}} className='body'>A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.<br/>
[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]<br/>
*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</div></Col></Row>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="partner" id="partner" title="Partner Data" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 25, marginRight: 25}}>
                <Col style={{marginTop: 30, marginBottom: 12}} md={12} lg={12}>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>CALENDAR</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div style={{paddingLeft: 12, paddingTop: 15, paddingBottom: 20}}>
                                <div className='h6-style'>Student Nominations must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarNomSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Applications for Nominated Students must reach the Receiving Institution by: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Autumn Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppAutumnPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={1}>Spring Term: </Col>
                                    <Col lg={11} className='body'>{calendarAppSpringPartner}</Col>
                                </Row>
                                <div className='h6-style' style={{marginTop: 22}}>Application procedure for Nominated Students: </div>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Email: </Col>
                                    <Col lg={10} className='body'>{applicationInfoEmailPartner}</Col>
                                </Row>
                                
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Contact Phone: </Col>
                                    <Col lg={10} className='body'>{applicationInfoPhonePartner}</Col>
                                </Row>
                                <Row style={{marginTop: 12}}>
                                    <Col lg={2}>Website for information: </Col>
                                    <Col lg={10} className='body'>{applicationInfoWebsitePartner?.length > 0 && applicationInfoWebsitePartner}</Col>
                                </Row>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                
                    
                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL REQUIREMENTS</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                            {additionalRequirementsPartner?.map((elem, index) => (
                                <div class="p-3  justify-content-around align-items-center">
                            
                                <div className="row">
                                    <div className="col table-header">
                                        {"REQUIREMENT:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"DETAILS:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.details}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"ECTS NUMBER:"}
                                    </div>
                                    <div className="col body">
                                        {elem?.ects}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"SUBJECT FIELD (ISCED):"}
                                    </div>
                                    <div className="col body">
                                        {elem?.isced_f_code + elem?.isced_f_code_name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"STUDY LEVEL (EQF):"}
                                    </div>
                                    <div className="col body">
                                        {elem?.eqf_level}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col table-header">
                                        {"WEBSITE:"}
                                    </div>
                                    <div className="col body">
                                    <a href={elem?.information_websites} target="_blank" style={{textDecoration: "none"}}>{elem?.information_websites}</a>
                                    </div>
                                </div>
                                </div>
                            ))}
                            <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will send its decision within 4 weeks, and no later than 5 weeks.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INCLUSION AND ACCESSIBILITY</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Infrastructure </div>
                                {accessibilitiesPartner?.map((elem, index) => (
                                    elem?.type === "infrastructure" && <div class="p-3  justify-content-around align-items-center">
                                    <div className="row">
                                        <div className="col table-header">
                                            {"NAME:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"DESCRIPTION:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.description}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"CONTACT:"}
                                        </div>
                                        <div className="col body">
                                        {elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                
                                
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>Services</div>
                                {accessibilitiesPartner?.map((elem, index) => (
                                    elem?.type === "service" && <div class="p-3  justify-content-around align-items-center">
                                    <div className="row">
                                        <div className="col table-header">
                                            {"NAME:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"DESCRIPTION:"}
                                        </div>
                                        <div className="col body">
                                            {elem?.description}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col table-header">
                                            {"CONTACT:"}
                                        </div>
                                        <div className="col body">
                                        {elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls ? ", " 
                                            + elem?.info?.website_urls : ""}
                                        </div>
                                    </div>
                                    </div>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>HOUSING</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will guide incoming mobile participants in finding accommodation, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{housingInfoPartner?.email}{housingInfoPartner?.phone_number?.e164 ? ", " + housingInfoPartner?.phone_number?.e164 : ""}{housingInfoPartner?.website_urls ? ", " + housingInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>VISA</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance, when required, in securing visas for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{visaInfoPartner?.email}{visaInfoPartner?.phone_number?.e164 ? ", " + visaInfoPartner?.phone_number?.e164 : ""}{visaInfoPartner?.website_urls ? ", " + visaInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>INSURANCE</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 8, fontStyle: "italic", paddingLeft: 12}}>The institution will provide assistance in obtaining insurance for incoming and outbound mobile participants, according to the requirements of the Erasmus Charter for Higher Education.<br/> The receiving institution will inform mobile participants of cases in which insurance cover is not automatically provided.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                <div className='h6-style' style={{marginTop: 10, paddingLeft: 12}}>Contact </div>
                                <div className='body' style={{marginTop: 8, paddingLeft: 12}}>{insuranceInfoPartner?.email}{insuranceInfoPartner?.phone_number?.e164 ? ", " + insuranceInfoPartner?.phone_number?.e164 : ""}{insuranceInfoPartner?.website_urls ? ", " + insuranceInfoPartner?.website_urls : ""}</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'>ADDITIONAL INFORMATION</Accordion.Header>
                            <Accordion.Body className='border-hidden label-style'>
                                <div className='body' style={{marginTop: 15, fontStyle: "italic", paddingLeft: 12}}>The institution will provide support to incoming mobile participants with special needs, according to the requirements of the Erasmus Charter for Higher Education.<br/> Information and assistance can be provided by the following contact points and information sources:</div>
                                {additionalInfosPartner?.map((elem, index) => (
                                    <>
                                    <div className='h6-style' style={{marginTop: 22, paddingLeft: 12}}>{elem?.type} </div>
                                    <Table responsive>
                                        <thead className='thead-font'>
                                            <tr>
                                            <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>CONTACT</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody-font'>
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{elem?.info?.email}{elem?.info?.phone_number?.e164 ? ", " 
                                            + elem?.info?.phone_number?.e164 : ""}{elem?.info?.website_urls?.length > 0 ? ", " 
                                            + elem?.info?.website_urls : ""}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </>
                                ))}
                                
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Row><Col style={{background: "#FFFFFF", borderRadius: 10, marginLeft: 8, marginRight: 8}}><div style={{marginLeft: 30, marginTop: 20, marginBottom: 20}} className='body'>A Transcript of Records will be issued by the institution no later than 6 weeks after the assessment period has finished.<br/>
[It should normally not exceed five weeks according to the Erasmus Charterfor Higher Education guidelines]<br/>
*After creating the profile of the higher education institution and adding the relevant general information, the higher education institution can proceed to generate Inter-Institutional Agreements with their selected partners.</div></Col></Row>
                </Col>
            </Row>
            </Tab>
            <Tab eventKey="conditions" id="conditions" title="Conditions" style={{background: "#EAECF3"}}>
            <Row style={{marginLeft: 5, marginRight: 5}}>
                <Col style={{marginTop: 15, marginBottom: 80}} md={12} lg={12}>
                
                {data?.iia?.cooperation_conditions?.staff_teachers.length > 0 && data?.iia?.cooperation_conditions?.staff_teachers.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><div><span className='blue-text'>Staff Teacher:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")}</div> </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Teachers</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.staff_trainings.length > 0 && data?.iia?.cooperation_conditions?.staff_trainings.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><div><span className='blue-text'>Staff Training:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")}</div> </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>Staff Trainings</td>
                                        <td className='border-hidden body'>{elem?.total_days_per_year ? elem?.total_days_per_year + " days per year" : ""}</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.student_studies.length > 0 && data?.iia?.cooperation_conditions?.student_studies.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><div><span className='blue-text'>Student Study:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")}</div> </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student studies"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                    {data?.iia?.cooperation_conditions?.student_traineeships.length > 0 && data?.iia?.cooperation_conditions?.student_traineeships.map((elem, index) => (
                        <Accordion className='border-hidden label-style' style={{marginBottom: 12}} id={"accordion-iias-active"}>
                        <Accordion.Item eventKey="0" className='label-style'>
                            <Accordion.Header className='document-item label-style'><div><span className='blue-text'>Student Traineeship:&nbsp;</span> <span className='red-text'>{elem?.sending_hei_id} &nbsp;</span> → <span className='red-text'>&nbsp;{elem?.receiving_hei_id} &nbsp;</span> / Study Fields: {elem?.subject_area?.map(element => element?.isced_f_code).join(", ")}</div> </Accordion.Header>
                            <Accordion.Body className='border-hidden label-style' >
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>HEIs </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDER</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVER</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'><span className='red-text'>{elem?.sending_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.sending_institution?.name}</span><br/> {elem?.sending_ounit_name}</td>
                                        <td style={{paddingBottom: 20}} className='border-hidden body'><span className='red-text'>{elem?.receiving_hei_id}&nbsp;</span> <span style={{fontStyle: "italic"}}>{elem?.receiving_institution?.name}</span><br/> {elem?.receiving_ounit_name}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 22, paddingLeft: 12, color: "#3D619C"}}>Condition Details </div>
                                <Row><Col lg={9}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>MOBILITY TYPE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>DURATION</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>ACADEMIC YEARS</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>EQF Level</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>SPOTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        <tr>
                                        <td style={{paddingLeft: 12}} className='border-hidden body'>{"Student trainships"}{elem?.blended === "1" && ", blended mobility"}</td>
                                        <td className='border-hidden body'>{elem?.total_months_per_year ? elem?.total_months_per_year + " months per year" : "" }</td>
                                        <td className='border-hidden body'>{elem?.receiving_acad_year[0]?.split("/")[0] + "/" + elem?.receiving_acad_year[elem?.receiving_acad_year.length - 1]?.split("/")[1] }</td>
                                        <td className='border-hidden body'>{elem?.eqf_level.length > 0 && elem?.eqf_level?.join(", ")}</td>
                                        <td className='border-hidden body'>{elem?.mobilities_per_year}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='label-style' style={{marginTop: 21, paddingLeft: 12}}>OTHER INFO</div>
                                <div className='body' style={{marginTop: 15, paddingLeft: 12}}>{elem?.other_info_terms}</div>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Language Requirements </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>LANGUAGE</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.language_skill?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.language}{element?.cefr_level ? ", " + element?.cefr_level : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Study Fields </div>
                                <Row><Col lg={8}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>STUDY FIELD</th>
                                        <th style={{paddingTop: 15, paddingBottom: 2, whiteSpace: "nowrap"}} className='border-hidden label-style'>CLARIFICATION</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.subject_area?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.isced_f_code}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <div className='h6-style' style={{marginTop: 40, paddingLeft: 12, color: "#3D619C"}}>Contacts </div>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12}} className='border-hidden label-style'>SENDING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.sending_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                                <Row><Col lg={10}>
                                <Table responsive>
                                    <thead className='thead-font'>
                                        <tr>
                                        <th style={{paddingTop: 15, paddingBottom: 2, paddingLeft: 12, whiteSpace: "nowrap"}} className='border-hidden label-style'>RECEIVING CONTACTS</th>
                                        </tr>
                                    </thead>
                                    <tbody className='tbody-font'>
                                        {elem?.receiving_contact?.map((element, index) => (
                                            <tr>
                                            <td style={{paddingBottom: 20, paddingLeft: 12}} className='border-hidden body'>{element?.person_given_names.length > 0 ? element?.person_given_names[0] + ", " : ""}
                                            {element?.emails.length > 0 ? element?.emails[0] : ""}{element?.phone_numbers.length > 0 ? ", " + element?.phone_numbers[0]?.e164 : ""}</td>
                                            <td style={{paddingBottom: 20}} className='border-hidden body'>{element?.isced_clarification}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </Col></Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    ))}
                </Col>
            </Row>
            </Tab>
            </Tabs>
        </Col>
    </Row>
    <Row style={{ marginLeft: 0, marginRight: 0}}>
        <Col style={{marginTop: 36, display: "flex", justifyContent: "center"}}>
            <CustomButton buttonType={"orange"} text={"edit iia"} styles={{marginLeft: 20}} icon={faPen} hasIcon={true} iconColor={COLORS.white} small disabled={loadingUpdateIIA || data?.iia?.status === "approved-by-all"} handleClick={() => navigate("/iias/edit/" + id)}/>
            <CustomButton buttonType={"accept"} text={"approve iia"} styles={{marginLeft: 20}} icon={faCheck} hasIcon={true} iconColor={COLORS.white} small disabled={loadingUpdateIIA || data?.iia?.status === "approved-by-all" || data?.iia?.status === "draft" || (data?.iia?.type_of_communication === "ewp" && !(data?.iia?.partner[0]?.iia_id && data?.iia?.partner[1]?.iia_id))} handleClick={() => setShowModalAccept(true)}/>
        </Col>
    </Row>
</>
  )}
  <CustomModal show={showModalAccept}  handleClose={() => setShowModalAccept(false)} 
            // body={"After deleting an IIA, you will not be able to see it again."}
            button={<CustomButton buttonType={"accept"} text={"Approve"} disabled={loadingUpdateIIA} icon={faCheck} hasIcon={true} iconColor={COLORS.white}  handleClick={handleUpdateIIA}/>} 
            title={data?.iia?.terminated ? <div>You are about to <span style={{color: "green"}}>approve</span> the termination of an Inter-institutional Agreement.</div> : <div>You are about to <span style={{color: "green"}}>approve</span> an Inter-institutional Agreement</div>} hasIcon={false} disabledCancelButton={loadingUpdateIIA}/>
    <CustomModal show={showModalTerminate}  handleClose={() => setShowModalTerminate(false)} 
        //     body={<><Form.Check type="checkbox">
        //     <Form.Check.Input
        //         type={"checkbox"}
        //         className="register"
        //         // isInvalid={validated && !checked}
        //         // isValid={validated && checked}
        //         value={checkedTerminate}
        //         onClick={(e) => setCheckedTerminate(!checkedTerminate)}
        //     />
        //     <Form.Check.Label>
        //     <div className="h4-style">The action you are about to take, results in <span style={{color: "red"}}>terminating</span> the in effect IIA and its irreversible. Do you still wish to continue?</div>
        //     </Form.Check.Label>
        // </Form.Check></>}
            body={"After terminating the IIA, your partner will be notified about your termination. The last approval remains valid and you can revert it back to its previously state at any time until both of you approve the IIA termination."}
            button={<CustomButton buttonType={"decline"} text={"Terminate"} disabled={loadingTerminateIIA} icon={faXmark} hasIcon={true} iconColor={COLORS.white}  handleClick={handleTerminate}/>} 
            title={<div>You are about to <span style={{color: "red"}}>terminate</span> an Inter-institutional Agreement</div>} hasIcon={false} disabledCancelButton={loadingTerminateIIA}/>
        <CustomModal show={showModalRevert}  handleClose={() => setShowModalRevert(false)} 
            // body={"After deleting an IIA, you will not be able to see it again."}
            button={<CustomButton buttonType={"primary"} text={"Revert"} disabled={loadingUpdateIIA} icon={faRotateLeft} hasIcon={true} iconColor={COLORS.white}  handleClick={handleRevertIIA}/>} 
            title={"You are about to revert the Inter-institutional Agreement to its previously approved by all state."} hasIcon={false} disabledCancelButton={loadingUpdateIIA}/>
        <CustomModal show={showModalModify}  handleClose={() => setShowModalModify(false)} 
            body={"After modifying the IIA, your partner will be notified about your changes. The last approval remains valid and you can revert it back to its previously state at any time until both of you re-approve the IIA."}
            button={<CustomButton buttonType={"orange"} text={"Modify"} icon={faPen} hasIcon={true} iconColor={COLORS.white}  handleClick={() => navigate("/iias/edit/" + id)}/>} 
            title={"You are about to modify and make changes to the Inter-institutional Agreement."} hasIcon={false}/>
    <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
  </>)
};

export default withFooter(
    withNavBar(withTitle(SingleViewIIAs, "IIAs Single View"), "Inter-Institutional Agreements", "Single View")
);