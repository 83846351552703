export const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "https://ewp-dashboard-dev02.it.auth.gr/graphql"
    : "PRODUCTION_URL";

    
export const ACCOUNT_TYPES = Object.freeze({
  hei: "HEI",
  eufAdmin: "EUFadmin",
});
